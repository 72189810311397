import { Component, OnInit } from '@angular/core';
import { ContentService } from '../common/content.service';

@Component({
  selector: 'app-parcours',
  templateUrl: './parcours.component.html',
  styleUrls: ['./parcours.component.scss']
})
export class ParcoursComponent implements OnInit {

  public sections;
  constructor(private contentService: ContentService) { }

  ngOnInit() {
    this.contentService.getSections('5e82017e3e64b418daa6e608')
      .subscribe(data => {
        this.sections = data;
      });
  }

}
